<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small"
						@click="config.isDetailDisplay = false">返回</el-button> </p>
				<div class="h-b"><el-button type="primary" size="small" @click="editApi"
						v-if="!config.isAdd && config.isCanEdit" v-right-code="'Auxiliarymaterial:Edit'">编辑</el-button> </div>
				<div class="h-b"><el-button type="primary" size="small" @click="saveApi"
						v-if="config.isAdd" v-right-code="'Auxiliarymaterial:Edit'">保存</el-button>
				</div>
			</div>
		</div>

		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane name="tabbasic">
				<span slot="label">基本信息</span>
				<el-form ref="_editForm" :model="dataSource">
					<el-collapse v-model.trim="formItems">
						<el-collapse-item title="基本信息" name="formItem">
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>辅材编码：</el-col>
								<el-col :span="5">
									<el-form-item prop="Code">
										<el-input v-model.trim="dataSource.Code" :maxlength="30" :minlength="4"
											placeholder="辅材编码"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>辅材名称：</el-col>
								<el-col :span="5">
									<el-form-item prop="Name">
										<el-input v-model.trim="dataSource.Name" :disabled="isDisabled" :maxlength="50"
											placeholder="设备名称" autofocus="autofocus"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>辅材类型：</el-col>
								<el-col :span="5">
									<el-form-item prop="DeviceType">
										<!-- <DictionarySelect v-model="dataSource.ASuxiliaryMaterialType"
											:disabled="isDisabled" datType='Int'
											dicTypeCode="AuxiliaryMaterialTypeCode">
										</DictionarySelect> -->
										<el-select v-model="dataSource.AuxiliaryMaterialType" placeholder="请选择"
											@change="changeType">
											<el-option v-for="item in typeList" :key="item.Key" :label="item.Value"
												:value="item.Key">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>型号：</el-col>
								<el-col :span="4">
									<el-form-item prop="Model">
										<!-- 	<DictionarySelect v-model="dataSource.model" :disabled="isDisabled"
											dicTypeCode="PaymentTypeCode">
										</DictionarySelect> -->
										<LookPopup v-model="dataSource.ModelName" dataTitle="型号选择"
										     ref="modelRef"
											dataUrl='omsapi/modelinfo/getpagelist' syncField='Name'
											@syncClickEvent="modelClick"
											:dataOptions="modellistOptions" @syncFieldEvent="syncModelEvent">
										</LookPopup>
									</el-form-item>
								</el-col>
								<el-col :span="2">
									<el-button @click="addModel">新增型号</el-button>
								</el-col>
								<el-col :span="2" class="form-title"> 数量(个)：</el-col>
								<el-col :span="5">
									<el-form-item prop="Quantity">
										<!-- 	<DictionarySelect v-model="dataSource.Quantity" :disabled="isDisabled"
											dicTypeCode="FormalContractTypeCode" @change="isFormalContractChange">
										</DictionarySelect> -->
										<el-input v-model="dataSource.Quantity" placeholder="单个重量(kg)"
											type="number"></el-input>

									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>规格：</el-col>
								<el-col :span="5">
									<el-form-item prop="Specifications">
										<el-input v-model="dataSource.Specifications" placeholder="规格"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>所属站点：</el-col>
								<el-col :span="5">
									<el-form-item prop="SiteId">
										<LookPopup v-model="dataSource.SiteName" dataTitle="供应商选择"
											dataUrl='omsapi/siteinfo/getpagelist' syncField='Name'
											@syncFieldEvent="syncSiteEvent">
										</LookPopup>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>单个重量(kg)：</el-col>
								<el-col :span="5">
									<el-form-item prop="SingleWeight">
										<el-input v-model="dataSource.SingleWeight" placeholder="单个重量(kg)"
											type="number"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title">备注：</el-col>
								<el-col :span="21">
									<el-form-item prop="Remarks">
										<el-input type="textarea" :disabled="isDisabled"
											v-model.trim="dataSource.Remark" :maxlength="300"
											placeholder="备注"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-collapse-item>
						<el-collapse-item title="其他信息" name="formItem2">
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>内尺寸(mm)：</el-col>
								<el-col :span="4">
									<el-form-item prop="Dimension1">
										<!-- <el-select v-model="dataSource.dimension1" :maxlength="200" :disabled="isDisabled" >
                                         <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                     </el-select> -->
										<el-input v-model="dataSource.Dimension1" type="number"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="4">
									<el-form-item prop="Dimension2">
										<el-input v-model="dataSource.Dimension2" type="number"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="5">
									<el-form-item prop="Dimension3">
										<el-input v-model="dataSource.Dimension3" type="number"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title">使用年限：</el-col>
								<el-col :span="5">
									<el-form-item prop="Life">
										<el-input v-model="dataSource.Life" placeholder="使用年限" type="number"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>重量(kg)：</el-col>
								<el-col :span="5">
									<el-form-item prop="Weight">
										<el-input v-model="dataSource.Weight" placeholder="重量(kg)"
											type="number"></el-input>
										<!-- 	<el-select v-model="dataSource.OurIdentity" :maxlength="200"
											:disabled="isDisabled">
											<el-option v-for="item in typeList" :key="item.value" :label="item.label"
												:value="item.value"></el-option>
										</el-select> -->
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>容积(L)：</el-col>
								<el-col :span="5">
									<el-form-item prop="Cubage">
										<!-- <el-input v-model="dataSource.SigningUnitName" readonly
											suffix-icon="el-icon-search" :disabled="isDisabled" placeholder="我方签订单位"
											@click.native="onSigningUnitClick"></el-input> -->
										<el-input v-model="dataSource.Cubage" placeholder="容积(L)"
											type="number"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;"></span>体积(m³)：</el-col>
								<el-col :span="5">
									<el-form-item prop="Volume">
										<el-input v-model="dataSource.Volume" placeholder="体积(m³)"
											type="number"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-collapse-item>
					</el-collapse>
				</el-form>
			</el-tab-pane>
		</el-tabs>
		<DialogEx :options="modelOptions" title="新增型号" @onOk="onModelOk">
			<el-form :model="modelDataSource">
				<el-form-item>
					<el-col :span="5">
						<center> 型号编码： </center>
					</el-col>
					<el-col :span="8">
						<el-input v-model="modelDataSource.Code" placeholder="系统自动生成" :disabled="true" type="text"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="5">
						<center> <span style="color:red;">*</span> 型号： </center>
					</el-col>
					<el-col :span="8">
						<el-input v-model="modelDataSource.Name" placeholder="型号" type="text"></el-input>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="5">
						<center> <span style="color:red;">*</span> 设备类型：</center>
					</el-col>
					<el-col :span="8">
						<el-select v-model="modelDataSource.AuxiliaryMaterialType" placeholder="请选择">
							<el-option v-for="item in typeList" :key="item.Key" :label="item.Value" :value="item.Key">
							</el-option>
						</el-select>
					</el-col>
				</el-form-item>
			</el-form>
		</DialogEx>
	</div>
</template>
<script>
	export default {
		mounted() {},
		data() {
			return {
				isDisabled: false,
				activeName: "tabbasic",
				formItems: ['formItem', 'formItem2'],
				validateRule: {
					Name: [{
						required: true,
						message: '请输入车型名称',
						trigger: 'blur'
					}],
					RateVolume: [{
						required: true,
						message: '请输入额定体积',
						trigger: 'blur'
					}],
					RateWeight: [{
						required: true,
						message: '请输入额定载重',
						trigger: 'blur'
					}],
				},
				dataSource: {

				},
				modelDataSource: {
					Code: "",
					Name: "",
					AuxiliaryMaterialType: null
				},
				modelOptions: {
					visible: false,
					size: 'small'
				},
				typeList: [{
						Key: 1,
						Value: "辅材"

					},
					{
						Key: 5,
						Value: "保温箱"

					},
					{
						Key: 10,
						Value: "冰排"

					},
					{
						Key: 15,
						Value: "温度计"
					}
				],
				modellistOptions: {
					AuxiliaryMaterialType: null

				}
			};
		},
		props: {
			config: {
				isDetailDisplay: false,
				dataSourceKey: null,
			},
		},
		watch: {
			'config.dataSourceKey': {
				handler(curVal, oldVal) {
					if (curVal && curVal > 0) {
						if (curVal != oldVal) this.getDetail(curVal); //初始化
					}
				},
				deep: true
			},
		},
		methods: {
			add() {
				var _this = this;
				this.$ajax.send("omsapi/auxiliarymaterial/getempty", "get", {}, (data) => {
					_this.dataSource = data.Result;
				});
			},
			editApi() {
				this.config.isAdd = true;
			},
			detailApi(apiId) {
				this.config.isAdd = false;
			},
			getDetail(apiId) {
				var _this = this;
				this.$ajax.send("omsapi/auxiliarymaterial/get", "get", {
					id: apiId
				}, (data) => {
					_this.dataSource = data.Result;
				});
			},
			saveApi() {
				this.$refs["_editForm"].validate((valid) => {
					if (valid) {
						var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
						var obj = this.Utils.cloneObj(this.dataSource);
						this.$ajax.send("omsapi/auxiliarymaterial/" + actionName, "post", obj, (data) => {
							this.config.dataSourceKey = data.Result;
							this.config.isCanEdit = false;
							this.config.isAdd = false;
							this.Event.$emit("reloadRolePageList");
							this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						this.Utils.messageBox("存在必填信息未输入.", "error");
						return false;
					}
				});
			},
			syncSiteEvent(row) {
				if (row) {
					this.dataSource.SiteId = row.Id;
					this.dataSource.SiteName = row.Name;
				}
			},
			addModel() {
				if (this.dataSource.AuxiliaryMaterialType == null) {
					this.Utils.messageBox("辅材类型未选择.", "error");
					return;
				}
		/* 	 	this.modelDataSource = {};
				this.modelDataSource.Code = null;
				this.modelDataSource.Name = null; */
				this.modelDataSource.AuxiliaryMaterialType = this.dataSource.AuxiliaryMaterialType;
				this.modelOptions.visible = true;
			},
			onModelOk(options) {
				var _this = this;
				if (_this.modelDataSource.Name == null) {
					_this.Utils.messageBox("型号未填写.", "error");
				}
				if (_this.modelDataSource.AuxiliaryMaterialType == null) {
					_this.Utils.messageBox("辅材类型未选择.", "error");
				}
				_this.$ajax.send("omsapi/modelinfo/create", "post", _this.modelDataSource, (data) => {
					if (data.IsSuccess) {
						_this.$ajax.send("omsapi/modelinfo/get?id="+data.Result, "get", {}, (data1) => {
							_this.dataSource.ModelNo = data1.Result.Code;
							_this.dataSource.ModelName = data1.Result.Name;
							_this.modelDataSource = {};
						});
					} else {
						options.visible = true;
						_this.Utils.messageBox(data.OperationDesc, "error");
						return false;
					}
				});
			},
			changeType() {
				this.modellistOptions.AuxiliaryMaterialType = this.dataSource.AuxiliaryMaterialType;
			},
			syncModelEvent(row) {
				if (row) {
					this.dataSource.ModelNo = row.Code;
					this.dataSource.ModelName = row.Name;
				}
			},
			modelClick(options){
				if (this.dataSource.AuxiliaryMaterialType == null) {
					this.Utils.messageBox("辅材类型未选择.", "error");
					options.visible=false;
					return
				}
				this.$refs.modelRef.initialize();
			}
		},
		components: {}
	}
</script>